
import { SectionWrapper } from './components';
import assets from './assets';
import styles from './styles/Global';

const App = () => {
  return (
    <>
      <SectionWrapper 
        title="ilet.im"
        description="Your own Messaging Service."
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
      />

<div className="px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04">
        <p className={`${styles.pText} ${styles.whiteText}`}>Forked with love from {" "}
        <span className="bold"><a href="https://github.com/adrianhajdin/" alt="Github" target="_blank" rel='noreferrer'>JavaScript Mastery</a></span>
        </p>
      </div>
    </>
  );
}

export default App;
